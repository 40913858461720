import React, { useEffect, useState } from 'react'

import hamburgerIcon from '../../../../static/svg/hamburger.svg'

import { GlobalStyle } from '../../../globalStyles'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { mediaQueries, PageTitles } from '../../../vars'
import { Nav } from './Nav'
import { Hamburger, HamburgerIcon } from './Nav/styles/Hamburger'
import { BackgroundDarken } from './Nav/styles'
import { SEO } from './SEO'
import { Container, Main } from './styles'

interface IProps {
  currentPageTitle: PageTitles | string
  children: React.ReactElement[] | React.ReactElement
  darkBg: boolean
  showContent?: boolean
}

export function Layout({
  children,
  currentPageTitle,
  showContent,
  darkBg,
}: IProps) {
  const useHamburger = useMediaQuery(
    `${mediaQueries.portrait.handheld}, ${mediaQueries.landscape.handheld}`,
  )

  const initialNavOpen        = !useHamburger
  const [navOpen, setNavOpen] = useState(initialNavOpen)

  useEffect(() => {
    setNavOpen(initialNavOpen)
  }, [useHamburger])

  function toggleNav() {
    setNavOpen(!navOpen)
  }

  return (
    <Container darkBg={darkBg}>
      <SEO title={currentPageTitle} />
      <GlobalStyle />

      <Nav
        open={navOpen}
        currentPageTitle={currentPageTitle}
        darkBg={darkBg}
        useHamburger={useHamburger}
      />
      {useHamburger && (
        <nav>
          <Hamburger
            title='menu'
            onClick={toggleNav}
            hide={showContent === false}
          >
            <HamburgerIcon
              src={hamburgerIcon}
              open={navOpen}
              alt='Hamburger icon'
            />
          </Hamburger>
        </nav>
      )}
      {navOpen && useHamburger && <BackgroundDarken onClick={toggleNav} />}

      <Main>{children}</Main>
    </Container>
  )
}
