import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { cardEnterAnim } from '../../../../../globalStyles'
import { rhythm } from '../../../../../utils/typography'
import { mediaQueries } from '../../../../../vars'

export const navZIndex        = 500
export const openAnimDuration = '0.4s'

export const BackgroundDarken = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${navZIndex - 1};
  background-color: #00000055;
`

export interface IDarkBg {
  darkBg: boolean
}

export interface IUseHamburger {
  useHamburger: boolean
}

interface IDropdown {
  dropdown: boolean
}

export const NavContainer = styled.nav<IDarkBg & IUseHamburger & IDropdown>`
  position: sticky;
  top: 0;
  perspective: 800px;

  @media ${mediaQueries.portrait.handheld}, ${mediaQueries.landscape.handheld} {
    transition: all ${openAnimDuration};
    transform-origin: bottom right;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg);
    border: 1px solid var(--primary);
    position: fixed;
    bottom: 0;
    right: 0;
    top: initial;
    z-index: ${navZIndex};
    width: 100vmin;
    height: 100vmin;
    box-shadow: var(--box-shadow);
    border-radius: ${({ dropdown }) => (dropdown ? 'unset' : '100% 0 0')};

    &.enter,
    &.exit-active {
      transform: scale(0);
    }
  }

  ${({ useHamburger, darkBg }) =>
  !useHamburger &&
  css`
      padding: ${rhythm(1)} 0;
      height: var(--nav-height);
      width: 100vw;
      z-index: 2;
      opacity: 0.9;
      ${darkBg
    ? css`
            background: #00000000;
            color: var(--white);
          `
    : css`
            background: #ffffff88;
            color: var(--black);
          `}
      display: flex;
      justify-content: flex-end;
      align-items: center;
    `}
`

export const Ul = styled.ul<IUseHamburger>`
  margin: ${rhythm(2)} 0 0 ${rhythm(2)};
  text-align: center;

  &.exit {
    display: none;
  }
  &.enter-done {
    display: unset;
  }

  ${({ useHamburger }) =>
  !useHamburger &&
  css`
      margin: 0;
      display: flex;
      align-items: center;

      &.enter-done {
        display: flex;
      }
    `}
`

interface ILiProps {
  current: boolean
  darkBg: boolean
}

export const ListItem = styled.li<ILiProps & IUseHamburger>`
  --border-width: 1px;
  list-style: none;
  text-decoration: ${({ current }) =>
  current ? 'underline var(--primary)' : 'none'};
  position: relative;
  animation: ${cardEnterAnim} 0.2s both;

  ${({ useHamburger, current, darkBg }) =>
  !useHamburger &&
  css`
      --border-width: 2px;
      //padding: ${rhythm(1)};
      height: var(--nav-height);
      margin: 0;
      display: flex;
      align-items: center;
      background-color: transparent;
      transition: background-color 0.2s;
      text-decoration: unset;
      border-bottom: ${
    current ? 'var(--border-width) solid var(--primary)' : 'none'
  };

      &:hover {
        background-color: ${darkBg ? '#00000044' : 'var(--primary-light)'};
      }
    `}
`

export const Icon = styled.img`
  display: block;
  height: 1.7rem;
  margin-left: ${rhythm(1 / 5)};
`

export const NavLink = styled(Link)`
  margin: 0 ${rhythm(1)};
  text-decoration: inherit;
  color: inherit;
  font-weight: bold;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const CartIconContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: -0.7rem;
    margin-left: 4px;
    color: var(--primary);
    font-weight: lighter;
  }
`
