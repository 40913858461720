import { Link } from 'gatsby'
import styled, { createGlobalStyle, css, keyframes } from 'styled-components'
import { rhythm } from './utils/typography'

export const GlobalStyle = createGlobalStyle`
  body {
    --nav-height: 80px;
    --primary: hsl(32, 95%, 50%);
    --primary-light: hsl(32, 95%, 90%);
    --primary-tl: hsla(32, 95%, 50%, 80%);
    --white: hsl(32, 95%, 99%);
    --white-tl: hsla(32, 95%, 100%, 80%);
    --black: hsl(32, 95%, 1%);
    --black-tl: hsla(32, 95%, 1%, 80%);
    --success: hsl(131, 52%, 59%);
    --bg: var(--white);
    --box-shadow-heavy: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    --box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    --box-shadow-inset: inset 0 3px 6px rgba(0, 0, 0, 0.16), inset 0 3px 6px rgba(0, 0, 0, 0.23);
    --box-shadow-light: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    --box-shadow-very-light: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0,0,0,.07), 0 1px 1.5px 0 rgba(0,0,0,.05);
    color: var(--black);
  }

`

export const Button = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
`

export const IconButton = styled(Button)`
  background: transparent;
  padding: 0;
  align-items: center;

  img {
    margin: 0;
  }
`

export const PrimaryButton = styled(Button)`
  width: max-content;
  background: var(--primary);
  color: var(--white);
  padding: ${rhythm(1 / 2)};
  border-radius: 10px;
  font-size: 1.1rem;
  letter-spacing: 1.2px;
  display: flex;
  align-items: center;
  border: 1px solid var(--primary);
  transition: box-shadow 0.3s;
  box-shadow: var(--box-shadow-light);

  img {
    max-height: 1.6rem;
    margin: 0;
    margin-left: 0.5rem;
  }

  :hover {
    box-shadow: var(--box-shadow);
  }
`

export const ButtonLink = styled(Link)`
  text-decoration: none;
  display: flex;
  cursor: pointer;
  width: max-content;
  background: var(--primary);
  color: var(--white);
  padding: ${rhythm(1 / 2)};
  border-radius: 10px;
  font-size: 1.1rem;
  letter-spacing: 1.2px;
  align-items: center;
  border: 1px solid var(--primary);
  transition: box-shadow 0.3s;
  box-shadow: var(--box-shadow-light);

  img {
    max-height: 1.2em;
    margin: 0 0 0 0.5rem;
  }

  :hover {
    box-shadow: var(--box-shadow);
  }
`

export const inputStyles = css`
  background-color: #fff;
  border-radius: 5px;
  height: max-content;
  width: 45%;
  border: 1px solid #ccc;
  box-shadow: var(--box-shadow-light);
`

export const cardEnterAnim = keyframes`
  from {
    opacity: 0;
    transform: scale(.97);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`
