import { MutableRefObject, useEffect } from 'react'

const documentGlobal: Document | false =
  typeof document !== 'undefined' && document

export function useClickOutside(
  ref: MutableRefObject<HTMLElement | null>,
  onClickOutside: () => void
) {
  if (
    !documentGlobal ||
    !documentGlobal.addEventListener ||
    !documentGlobal.removeEventListener
  ) {
    return
  }

  function handleClickOutside(event: MouseEvent) {
    const target = event.target as Node

    if (ref.current && !ref.current.contains(target)) {
      onClickOutside()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}
