import { cart } from '../@types'
import { storageKey } from '../vars'

const windowGlobal: Window | false = typeof window !== 'undefined' && window

export function getCurrentCart(): cart | null {
  if (!windowGlobal || !windowGlobal.localStorage) {
    return null
  }

  const rawCart: string | null = windowGlobal.localStorage.getItem(storageKey)

  const currentCart: cart | null = rawCart && JSON.parse(rawCart)

  const cartWithImage =
    currentCart &&
    currentCart.map(item => {
      const { fluid, fluidLarge, fluidSmall } = item.photo.asset
      const src = fluid
        ? fluid.src
        : fluidLarge
        ? fluidLarge.src
        : fluidSmall
        ? fluidSmall.src
        : ''
      return { ...item, checkoutImgSrc: src }
    })

  return cartWithImage && cartWithImage[0] ? cartWithImage : null
}
