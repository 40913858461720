import { useEffect, useState } from 'react'

const windowGlobal: Window | false = (typeof window !== 'undefined' &&
                                      window) as Window | false

export function useMediaQuery(query: string): boolean {
  if (!windowGlobal || !windowGlobal.matchMedia) {
    return false
  }

  const mediaQueryList = windowGlobal.matchMedia(query)

  function getMatches(): boolean {
    return mediaQueryList.matches
  }

  const [matches, setMatches] = useState<boolean>(getMatches)

  useEffect(() => {
    function handler() {
      setMatches(getMatches)
    }

    mediaQueryList.addListener(handler)

    return () => mediaQueryList.removeListener(handler)
  }, [])

  return matches
}
