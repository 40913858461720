import React, { useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import cartIcon from '../../../../../static/svg/cart.svg'
import home from '../../../../../static/svg/home-alt.svg'
import photo from '../../../../../static/svg/image.svg'
import contactIcon from '../../../../../static/svg/contact-book.svg'
import personIcon from '../../../../../static/svg/user.svg'

import { INavQuery } from '../../../../@types'
import { getCurrentCart } from '../../../../functions/getCurrentCart'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import { PageTitles, PageURLs } from '../../../../vars'
import { Dropdown } from './Dropdown'
import { INavProps } from './index'
import {
  CartIconContainer,
  Icon,
  ListItem,
  NavContainer,
  NavLink,
  Ul,
} from './styles'

interface IPureNavProps extends INavProps {
  data: INavQuery
}

export function PureNav({
  currentPageTitle,
  open,
  darkBg,
  useHamburger,
  data,
}: IPureNavProps) {
  const { nodes: categories } = data.allSanityCategory

  const [dropdown, setDropdown] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null)

  useClickOutside(dropdownRef, removeDropdown)

  function removeDropdown() {
    if (dropdown) {
      setDropdown(false)
    }
  }

  function addDropdown() {
    if (!dropdown) {
      setDropdown(true)
    }
  }

  function conditionalLinkCancel(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) {
    if (useHamburger) {
      event.preventDefault()
    }
  }

  const cartNumber = getCartNumber()

  let photosIsCurrentPage: boolean = currentPageTitle === PageTitles.Photos

  function setPhotosIsCurrentPage(newVal: boolean) {
    photosIsCurrentPage = newVal
  }

  const dropdownEl = (
    <Dropdown
      categories={categories}
      currentPageTitle={currentPageTitle}
      darkBg={darkBg}
      dropdown={dropdown}
      dropdownRef={dropdownRef}
      removeDropdown={removeDropdown}
      setPhotosIsCurrentPage={setPhotosIsCurrentPage}
      useHamburger={useHamburger}
    />
  )

  const showMainLinks = (useHamburger && !dropdown) || !useHamburger

  return (
    <CSSTransition
      in={open}
      timeout={{ enter: 0, exit: 500 }}
      mountOnEnter={true}
      unmountOnExit={true}
      appear={true}
    >
      <NavContainer
        darkBg={darkBg}
        useHamburger={useHamburger}
        dropdown={dropdown}
      >
        {useHamburger && dropdownEl}
        <CSSTransition
          in={showMainLinks}
          timeout={200}
          mountOnEnter={true}
          unmountOnExit={true}
          appear={true}
        >
          <Ul useHamburger={useHamburger}>
            <ListItem
              darkBg={darkBg}
              current={currentPageTitle === PageTitles.Home}
              useHamburger={useHamburger}
            >
              <NavLink to={PageURLs.Home}>
                <span>{PageTitles.Home}</span>
                <Icon src={home} alt='Home icon' />
              </NavLink>
            </ListItem>

            <ListItem
              darkBg={darkBg}
              current={photosIsCurrentPage}
              useHamburger={useHamburger}
              onClick={addDropdown}
              onMouseEnter={!useHamburger ? addDropdown : undefined}
              onMouseLeave={!useHamburger ? removeDropdown : undefined}
              onFocus={!useHamburger ? addDropdown : undefined}
              onBlur={!useHamburger ? removeDropdown : undefined}
            >
              <NavLink to={PageURLs.Photos} onClick={conditionalLinkCancel}>
                <span>{PageTitles.Photos}</span>
                <Icon src={photo} alt='Photos icon' />
              </NavLink>
              {!useHamburger && dropdownEl}
            </ListItem>

            <ListItem
              darkBg={darkBg}
              current={currentPageTitle === PageTitles.Contact}
              useHamburger={useHamburger}
            >
              <NavLink to={PageURLs.Contact}>
                <span>{PageTitles.Contact}</span>
                <Icon src={contactIcon} alt='People icon' />
              </NavLink>
            </ListItem>

            <ListItem
              darkBg={darkBg}
              current={currentPageTitle === PageTitles.About}
              useHamburger={useHamburger}
            >
              <NavLink to={PageURLs.About}>
                <span>{PageTitles.About}</span>
                <Icon src={personIcon} alt='Person icon' />
              </NavLink>
            </ListItem>

            <ListItem
              darkBg={darkBg}
              current={currentPageTitle === PageTitles.Cart}
              useHamburger={useHamburger}
            >
              <NavLink to={PageURLs.Cart}>
                <span>{PageTitles.Cart}</span>
                <CartIconContainer>
                  {cartNumber && <span>{cartNumber}</span>}
                  <Icon src={cartIcon} alt='Cart icon' />
                </CartIconContainer>
              </NavLink>
            </ListItem>
          </Ul>
        </CSSTransition>
      </NavContainer>
    </CSSTransition>
  )
}

function getCartNumber(): string | number | null {
  const cart = getCurrentCart()

  const cartLength = cart && cart.length

  return cartLength && cartLength > 0 && cartLength < 10
    ? cartLength
    : cartLength && cartLength === 0
      ? null
      : cartLength && cartLength >= 10
        ? '9+'
        : null
}
