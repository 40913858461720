import styled from 'styled-components'
import { IconButton } from '../../../../../globalStyles'
import { navZIndex, openAnimDuration } from './index'

interface IHide {
  hide: boolean
}

export const Hamburger = styled(IconButton)<IHide>`
  opacity: ${({ hide }) => (hide ? 0 : 0.8)};
  transition: opacity 1s;

  --diameter: 40vmin;

  position: fixed;
  bottom: calc(var(--diameter) / -2);
  right: calc(var(--diameter) / -2);
  width: var(--diameter);
  height: var(--diameter);
  border-radius: 50%;
  margin: 0;
  background: var(--primary);
  z-index: ${navZIndex + 1};
`

interface IOpen {
  open: boolean
}

export const HamburgerIcon = styled.img<IOpen>`
  --width: calc(var(--diameter) / 2.5);
  transform: ${props => (props.open ? 'rotate(-90deg)' : 'rotate(0)')};
  transition: transform ${openAnimDuration};
  height: var(--width);
  width: var(--width);
  margin: 0;
  position: absolute;
  left: calc(var(--diameter) / 2 - var(--width));
  top: calc(var(--diameter) / 2 - var(--width));
`
