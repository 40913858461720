import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import { IDarkBg, IUseHamburger } from '.'
import { cardEnterAnim, IconButton } from '../../../../../globalStyles'
import { rhythm } from '../../../../../utils/typography'

export const PhotoCategories = styled.div<IUseHamburger & IDarkBg>`
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  transition: all 0.2s;

  a:first-of-type {
    grid-area: 1 / 1 / 1 / 3;
  }

  ${({ useHamburger, darkBg }) =>
    !useHamburger
      ? css`
          z-index: 1;
          position: absolute;
          width: 200%;
          top: 100%;
          left: -50%;
          ${darkBg
            ? css`
                background: #000000dd;
                color: var(--white);
              `
            : css`
                background: white;
                color: var(--black);
              `}
          box-shadow: var(--box-shadow);
          border-radius: 10px;
          padding: ${rhythm(3 / 2)};
          gap: ${rhythm(3 / 2)};

          &.enter,
          &.enter-active,
          &.exit,
          &.exit-done {
            transform: rotateX(-20deg);
            opacity: 0;
          }
          &.enter-done {
            transform: rotateX(0deg);
            opacity: 1;
          }
        `
      : css`
          gap: ${rhythm(1 / 4)};
          &.exit {
            display: none;
          }
          &.enter-done {
            display: grid;
          }
        `}
`

interface ICurrent {
  current: boolean
}

export const CategoryLink = styled(({ darkBg, current, ...rest }) => (
  <Link {...rest} />
))<IDarkBg & ICurrent>`
  text-decoration: inherit;
  color: inherit;
  font-weight: bold;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${rhythm(1 / 3)};
  border-radius: 10px;
  transition: background-color 0.2s;
  animation: ${cardEnterAnim} 0.2s both;

  img {
    margin: 0 auto;
  }

  span {
    margin: 0 auto;
    border-bottom: ${({ current }) =>
      current ? '2px solid var(--primary)' : 'none'};
  }

  &:hover {
    background-color: ${({ darkBg }) =>
      darkBg ? '#ffffff33' : 'var(--primary-light)'};
  }
`

export const BackButton = styled(IconButton)`
  animation: ${cardEnterAnim} 0.2s both;
  position: absolute;
  top: ${rhythm(1 / 2)};
  right: ${rhythm(1 / 2)};

  img {
    height: 2rem;
  }
`
