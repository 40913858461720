import Typography from 'typography'
import bootstrapTheme from 'typography-theme-bootstrap'

bootstrapTheme.bodyFontFamily = ['Arial', 'sans-serif']
bootstrapTheme.headerFontFamily = ['Arial', 'sans-serif']
bootstrapTheme.headerWeight = 'bold'

const typography = new Typography(bootstrapTheme)

export const { scale, rhythm, options } = typography
export default typography
