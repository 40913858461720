import styled, { css } from 'styled-components'

import { mediaQueries } from '../../../vars'
import { IDarkBg } from './Nav/styles'

export const Container = styled.div<IDarkBg>`
  border-top: 2px solid var(--primary);
  margin: 0 auto;

  ${({ darkBg }) =>
  !darkBg &&
  css`
      ::after {
        content: '';
        background-size: 50%;
        opacity: 0.2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
      }
    `}
`

export const Main = styled.main`
  min-height: 100vh;
  margin: 0 auto;

  @media ${mediaQueries.general.tablet} {
    margin: 0 auto;
  }
`
